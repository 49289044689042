import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import {isTouch, getTheOs} from "modules/utils.js"
// import {scroll} from "modules/constants"
import {lenis} from "./constants"
import {Webgl} from "Webgl"


export class Home{
    constructor(){
        // introduction
        //
        //
        //
        //
        //

        this.ratioTailleForme = 0;
        if(window.innerWidth <= 768){
            this.ratioTailleForme = 0.5;
        }else{
            this.ratioTailleForme = 1.3;
        }
        console.log(this.ratioTailleForme)

        if(window.innerWidth <= 768){
            gsap.set('#shapeIntro #forme1Intro', {
                x:(window.innerWidth/2 - (0.1/this.ratioTailleForme)) + 'px',
                y:(window.innerHeight/2 - 0.18*window.innerWidth) + 'px',
                scaleY: (0.2/this.ratioTailleForme)*window.innerWidth/159,
                scaleX: 0
            })
        }else{
            gsap.set('#shapeIntro #forme1Intro', {
                x:(window.innerWidth/2 - (0.1/this.ratioTailleForme)) + 'px',
                y:(0.73 * window.innerHeight - (0.294/this.ratioTailleForme) * window.innerWidth/2) + 'px',
                scaleY: (0.2/this.ratioTailleForme)*window.innerWidth/159,
                scaleX: 0
            })
        }

        gsap.delayedCall(1, () => {

            document.querySelector('.video-container1 video').classList.add('on')
            gsap.to('#shapeIntro #forme1Intro', {
                x:(window.innerWidth/2 - (0.2/this.ratioTailleForme)*window.innerWidth/2) + 'px',
                // y:(0.73 * window.innerHeight - (0.294/this.ratioTailleForme) * window.innerWidth/2) + 'px',
                scaleX: (0.2/this.ratioTailleForme)*window.innerWidth/159,
                duration:1.2,
                delay:0.4,
                ease:'power4.inOut',
                onUpdate: () => {
                    document.querySelector('.video-container1').style.clipPath = "none";
                    document.querySelector('.video-container1').offsetWidth;
                    document.querySelector('.video-container1').style.clipPath = "url(#shapeIntro)"
                },
                onComplete:() => {
                    document.querySelector('.video-container1').style.clipPath = "none";
                    document.querySelector('.video-container1').offsetWidth;
                    document.querySelector('.video-container1').style.clipPath = "url(#shape1)"

                    document.querySelector('.dOff').classList.add('off')
                    document.querySelector('div.h1').classList.add('on')
                    // if(!isTouch() && getTheOs() != 'Windows'){
                    //     scroll.resize();
                    //     scroll.wheel();
                    // }

                    if(!isTouch()){
                        lenis.start();
                    }
                }
            })

            const vitesse = 0.8
            
            gsap.to('.titreHome .l1', {
                y:'0%',
                scale:1,
                opacity:1,
                ease:'power4.out',
                duration:2 * vitesse
            })
            gsap.to('.titreHome .l2', {
                y:'0%',
                scale:1,
                opacity:1,
                ease:'power4.out',
                duration:1.9 * vitesse,
                delay:0.1 * vitesse
            })
            gsap.to('.titreHome .l3', {
                y:'0%',
                scale:1,
                opacity:1,
                ease:'power4.out',
                duration:1.8 * vitesse,
                delay:0.2 * vitesse
            })
            gsap.to('.titreHome .l4', {
                y:'0%',
                scale:1,
                opacity:1,
                ease:'power4.out',
                duration:1.7 * vitesse,
                delay:0.3 * vitesse
            })
            gsap.to('.titreHome .l5', {
                y:'0%',
                scale:1,
                opacity:1,
                ease:'power4.out',
                duration:1.6 * vitesse,
                delay:0.4 * vitesse
            })
            gsap.to('.titreHome .l6', {
                y:'0%',
                scale:1,
                opacity:1,
                ease:'power4.out',
                duration:1.5 * vitesse,
                delay:0.5 * vitesse
            })
            gsap.to('.titreHome .l7', {
                y:'0%',
                scale:1,
                opacity:1,
                ease:'power4.out',
                duration:1.4 * vitesse,
                delay:0.6 * vitesse
            })

            gsap.to('.introStag', {
                y:0,
                opacity:1,
                ease:'power4.out',
                duration:1.5,
                stagger:0.07,
                delay:1.6
            })
        })
        





        

        //    //
        //  ////
        //    //
        //    //
        //    //
        //
        // a garder pour safari ??? CHELOU X 1000
        gsap.to('#logoSVG1', {
            width:'100%',
            height:'100%',
            ease:'none',
            scrollTrigger: {
                trigger:'#part1 .flexHero',
                start:'top top',
                end:'+=' + window.innerHeight,
                scrub:true,
                refreshPriority:100
            }
        })

        if(window.innerWidth <= 768){
            gsap.set('#shape1 #forme1', {
                x:(window.innerWidth/2 - (0.2/this.ratioTailleForme)*window.innerWidth/2) + 'px',
                y:(window.innerHeight/2 - 0.18*window.innerWidth) + 'px',
                scale: (0.2/this.ratioTailleForme)*window.innerWidth/159
            })
        }else{
            gsap.set('#shape1 #forme1', {
                x:(window.innerWidth/2 - (0.2/this.ratioTailleForme)*window.innerWidth/2) + 'px',
                y:(0.73 * window.innerHeight - (0.294/this.ratioTailleForme) * window.innerWidth/2) + 'px',
                scale: (0.2/this.ratioTailleForme)*window.innerWidth/159
            })
        }
        
        

        gsap.to('.video-container1 video', {
            scale:1,
            y:0,
            ease:'none',
            scrollTrigger: {
                trigger:'#part1 .flexHero',
                start:'top top',
                end:'+=' + window.innerHeight,
                scrub:true,
                refreshPriority:99
            }
        })

        gsap.to('#shape1 #forme1', {
            morphSVG:"#shape1 #forme2", 
            scaleX: window.innerWidth/159,
            scaleY: window.innerHeight/234,
            x:'0px',
            y:'0px',
            ease:'none',
            immediateRender:false,
            scrollTrigger: {
                id:'id1',
                trigger:'#part1 .flexHero',
                start:'top top',
                end:'+=' + window.innerHeight,
                scrub:true,
                pin:true,
                onLeave:() => {document.querySelector('.video-container1').classList.add('off')},
                onEnterBack:() => {document.querySelector('.video-container1').classList.remove('off')},
                refreshPriority:98
            }
        })

        gsap.to('.details', {
            scale:0.2,
            autoAlpha: 0,
            ease:'power2.in',
            scrollTrigger: {
                trigger:'.contentPart1',
                start:'top bottom+=' + window.innerHeight/4 + 'px',
                end:'+=' + window.innerHeight*0.75 + 'px',
                scrub: true,
                onLeave: () => {
                    document.getElementById('hero').classList.add('off')
                },
                onEnterBack: () => {document.getElementById('hero').classList.remove('off')},
                refreshPriority:97
            }
        })

        // if(window.innerWidth > 900){
        //     gsap.to('.innerRetail', {
        //         y:'-300%',
        //         ease:'power3.in',
        //         scrollTrigger: {
        //             trigger:'.contentPart1',
        //             start:'top bottom+=' + window.innerHeight,
        //             end:'+=' + window.innerHeight/2 + 'px',
        //             scrub: true,
        //             refreshPriority:96
        //         }
        //     })
        // }
        
        gsap.to('.vintageL', {
            y:'-300%',
            ease:'power3.in',
            scrollTrigger: {
                trigger:'.contentPart1',
                start:'top bottom+=' + window.innerHeight,
                end:'+=' + window.innerHeight/2 + 'px',
                scrub: true,
                refreshPriority:96
            }
        })
        gsap.to('.infosBas .gauche', {
            y:'-100%',
            autoAlpha: 0,
            ease:'power3.in',
            immediateRender:false,
            scrollTrigger: {
                trigger:'.contentPart1',
                start:'top bottom+=' + window.innerHeight/2,
                end:'+=' + window.innerHeight/2 + 'px',
                scrub: true,
                refreshPriority:95
            }
        })
        gsap.to('.infosBas .droite', {
            autoAlpha: 0,
            ease:'none',
            immediateRender:false,
            scrollTrigger: {
                trigger:'.contentPart1',
                start:'top bottom+=' + window.innerHeight*0.75,
                end:'+=' + window.innerHeight/2 + 'px',
                scrub: true,
                refreshPriority:94
            }
        })
        //
        //
        //
        //





        //   ////
        //      //
        //  ////
        //  //
        //  //////
        //
        // a garder pour safari ??? CHELOU X 1000
        gsap.to('#logoSVG2', {
            width:'100%',
            height:'100%',
            ease:'none',
            scrollTrigger: {
                trigger:'#part2 .flexHero',
                start:'top top',
                end:'+=' + window.innerHeight,
                scrub:true,
                refreshPriority:93
            }
        })

        gsap.to('.video-container2, .video-container2 video', {
            ease:'none',
            y:0,
            scrollTrigger: {
                trigger:'#part2',
                start:'top bottom',
                end:'top top',
                scrub:true,
                onEnter: () => {
                    document.querySelector('.video-container2 video').play()
                    document.getElementById('part2').classList.remove('off')
                },
                onLeaveBack: () => {
                    document.querySelector('.video-container2 video').pause()
                    document.getElementById('part2').classList.add('off')
                },
                refreshPriority:92   
            }
        })
        
        gsap.set('#shape2 #forme1', {
            x:(window.innerWidth/2 - (0.2/this.ratioTailleForme)*window.innerWidth/2) + 'px',
            y:(window.innerHeight/2 - (0.294/this.ratioTailleForme) * window.innerWidth/2) + 'px',
            scale: (0.2/this.ratioTailleForme)*window.innerWidth/159
        })

        gsap.to('.video-container2 video', {
            scale:1,
            ease:'none',
            scrollTrigger: {
                trigger:'#part2 .flexHero',
                start:'top top',
                end:'+=' + window.innerHeight,
                scrub:true,
                refreshPriority:91
            }
        })

        gsap.to('#shape2 #forme1', {
            morphSVG:"#shape2 #forme2", 
            scaleX: window.innerWidth/159,
            scaleY: window.innerHeight/234,
            x:'0px',
            y:'0px',
            ease:'none',
            scrollTrigger: {
                id:'id2',
                trigger:'#part2 .flexHero',
                start:'top top',
                end:'+=' + window.innerHeight,
                scrub:true,
                pin:true,
                onLeave: () => {
                    document.querySelector('.video-container2').classList.add('off')
                    document.querySelector('.video-container1 video').pause()
                    document.getElementById('part1').classList.add('off')
                },
                onEnterBack: () => {
                    document.querySelector('.video-container2').classList.remove('off')
                    document.querySelector('.video-container1 video').play()
                    document.getElementById('part1').classList.remove('off')
                },
                refreshPriority:90
            }
        })
        //
        //
        //
        //

        //   ////
        //      //
        //  ////
        //      //
        //  ////
        //
        // a garder pour safari ??? CHELOU X 1000
        gsap.to('#logoSVG3', {
            width:'100%',
            height:'100%',
            ease:'none',
            scrollTrigger: {
                trigger:'#part3 .flexHero',
                start:'top top',
                end:'+=' + window.innerHeight,
                scrub:true,
                refreshPriority: 89
            }
        })

        gsap.to('.video-container3, .video-container3 video', {
            ease:'none',
            y:0,
            scrollTrigger: {
                trigger:'#part3',
                start:'top bottom',
                end:'top top',
                scrub:true,
                onEnter: () => {
                    document.querySelector('.video-container3 video').play()
                    document.getElementById('part3').classList.remove('off')
                },
                onLeaveBack: () => {
                    document.querySelector('.video-container3 video').pause()
                    document.getElementById('part3').classList.add('off')
                },
                refreshPriority: 88
            }
        })
        
        gsap.set('#shape3 #forme1', {
            x:(window.innerWidth/2 - (0.2/this.ratioTailleForme)*window.innerWidth/2) + 'px',
            y:(window.innerHeight/2 - (0.294/this.ratioTailleForme) * window.innerWidth/2) + 'px',
            scale: (0.2/this.ratioTailleForme)*window.innerWidth/159
        })

        gsap.to('.video-container3 video', {
            scale:1,
            ease:'none',
            scrollTrigger: {
                trigger:'#part3 .flexHero',
                start:'top top',
                end:'+=' + window.innerHeight,
                scrub:true,
                refreshPriority: 87
            }
        })

        gsap.to('#shape3 #forme1', {
            morphSVG:"#shape3 #forme2", 
            scaleX: window.innerWidth/159,
            scaleY: window.innerHeight/234,
            x:'0px',
            y:'0px',
            ease:'none',
            scrollTrigger: {
                id:'id3',
                trigger:'#part3 .flexHero',
                start:'top top',
                end:'+=' + window.innerHeight,
                scrub:true,
                pin:true,
                onLeave: () => {
                    document.querySelector('.video-container3').classList.add('off')
                    document.querySelector('.video-container2 video').pause()
                    document.getElementById('part2').classList.add('off')
                },
                onEnterBack: () => {
                    document.querySelector('.video-container3').classList.remove('off')
                    document.querySelector('.video-container2 video').play()
                    document.getElementById('part2').classList.remove('off')
                },
                refreshPriority: 86
            }
        })
        //
        //
        //
        //





        //
        //
        // RESIZE()
        //
        //
        this.resize = this.eventResize.bind(this);
        window.addEventListener('resize', this.resize);

        



        //
        //
        // CONTENT
        //
        //
        //
        document.querySelectorAll('.content .grosP').forEach(elmt => {

            gsap.to(elmt.querySelectorAll('.stag'), {
                autoAlpha:1,
                scale:1,
                ease:'power4.out',
                duration:0.8,
                stagger:0.04,
                immediateRender:false,
                scrollTrigger: {
                    trigger:elmt,
                    start:'top bottom'
                }
            })
        })


        //
        //  //////
        //  //
        //  /////
        //  //
        //  //
        //
        gsap.to('.video-container3', {
            opacity:0,
            ease:'none',
            scrollTrigger: {
                trigger:'#frame',
                start:'top bottom',
                end:'+=' + window.innerHeight,
                scrub: true
            }
        })
        gsap.to('.bordG, .bordD, .triangle', {
            x:'0%',
            ease:'none',
            scrollTrigger: {
                trigger:'#frame',
                start:'top 50%',
                end:'+=' + window.innerHeight,
                scrub: true,
                onEnter: () => {
                    document.querySelector('.bordures').classList.add('on')
                    document.getElementById('webgl').classList.add('on')
                    console.log('RAF ON')
                    this.webgl.lancerRaf()
                },
                onLeaveBack: () => {
                    document.querySelector('.bordures').classList.remove('on')
                    document.getElementById('webgl').classList.remove('on')
                    console.log('RAF OFF')
                    this.webgl.killRaf()
                }
            }
        })
        gsap.to('.bordH, .bordB', {
            y:'0%',
            ease:'none',
            scrollTrigger: {
                trigger:'#frame',
                start:'top 50%',
                end:'+=' + window.innerHeight,
                scrub: true
            }
        })


        //
        //  //
        //  //
        //  //
        //  //
        //  //////
        //
        ScrollTrigger.create({
            trigger:'#love',
            start:'top top',
            onEnter: () => {
                console.log('RAF OFF')
                this.webgl.killRaf()
            },
            onLeaveBack: () => {
                console.log('RAF ON')
                this.webgl.lancerRaf()
            }
        })

        if(window.innerWidth >= 768){
            gsap.to('.img2', {
                y:'-200px',
                ease:'none',
                scrollTrigger: {
                    trigger:'.innerTrio',
                    start:'top bottom',
                    end:'bottom top',
                    scrub:true
                }
            })
            gsap.to('.img3', {
                y:'-400px',
                ease:'none',
                scrollTrigger: {
                    trigger:'.innerTrio',
                    start:'top bottom',
                    end:'bottom top',
                    scrub:true
                }
            })
        }

        document.querySelectorAll('.paraP').forEach(el => {
            gsap.fromTo(el, {
                y:'200px'
            }, {
                y:'-200px',
                ease:'none',
                scrollTrigger: {
                    trigger: el,
                    start: 'top bottom',
                    end: 'bottom top',
                    scrub: true
                }
            })
        })


        //
        //
        // webgl
        //
        //
        this.webgl = new Webgl();

    }

    eventResize(){
        
        ScrollTrigger.getById('id1').kill()
        ScrollTrigger.getById('id2').kill()
        ScrollTrigger.getById('id3').kill()

        // 1
        if(window.innerWidth <= 768){
            gsap.set('#shape1 #forme1', {
                x:(window.innerWidth/2 - (0.2/this.ratioTailleForme)*window.innerWidth/2) + 'px',
                y:(window.innerHeight/2 - 0.18*window.innerWidth) + 'px',
                scale: (0.2/this.ratioTailleForme)*window.innerWidth/159
            })
        }else{
            gsap.set('#shape1 #forme1', {
                x:(window.innerWidth/2 - (0.2/this.ratioTailleForme)*window.innerWidth/2) + 'px',
                y:(0.73 * window.innerHeight - (0.294/this.ratioTailleForme) * window.innerWidth/2) + 'px',
                scale: (0.2/this.ratioTailleForme)*window.innerWidth/159
            })
        }

        // 2
        gsap.set('#shape2 #forme1', {
            x:(window.innerWidth/2 - (0.2/this.ratioTailleForme)*window.innerWidth/2) + 'px',
            y:(window.innerHeight/2 - (0.294/this.ratioTailleForme) * window.innerWidth/2) + 'px',
            scale: (0.2/this.ratioTailleForme)*window.innerWidth/159
        })

        // 3
        gsap.set('#shape3 #forme1', {
            x:(window.innerWidth/2 - (0.2/this.ratioTailleForme)*window.innerWidth/2) + 'px',
            y:(window.innerHeight/2 - (0.294/this.ratioTailleForme) * window.innerWidth/2) + 'px',
            scale: (0.2/this.ratioTailleForme)*window.innerWidth/159
        })


        // 1
        gsap.fromTo('#shape1 #forme1', {
            x:(window.innerWidth/2 - (0.2/this.ratioTailleForme)*window.innerWidth/2) + 'px',
            y:(0.73 * window.innerHeight - (0.294/this.ratioTailleForme) * window.innerWidth/2) + 'px',
            scale: (0.2/this.ratioTailleForme)*window.innerWidth/159
        },{
            morphSVG:"#shape1 #forme2", 
            scaleX: window.innerWidth/159,
            scaleY: window.innerHeight/234,
            x:'0px',
            y:'0px',
            ease:'none',
            immediateRender:false,
            scrollTrigger: {
                id:'id1',
                trigger:'#part1 .flexHero',
                start:'top top',
                end:'+=' + window.innerHeight,
                scrub:true,
                pin:true,
                onLeave:() => {document.querySelector('.video-container1').classList.add('off')},
                onEnterBack:() => {document.querySelector('.video-container1').classList.remove('off')},
                refreshPriority:98
            }
        })

        // 2
        gsap.to('#shape2 #forme1', {
            morphSVG:"#shape2 #forme2", 
            scaleX: window.innerWidth/159,
            scaleY: window.innerHeight/234,
            x:'0px',
            y:'0px',
            ease:'none',
            scrollTrigger: {
                id:'id2',
                trigger:'#part2 .flexHero',
                start:'top top',
                end:'+=' + window.innerHeight,
                scrub:true,
                pin:true,
                onLeave: () => {
                    document.querySelector('.video-container2').classList.add('off')
                    document.querySelector('.video-container1 video').pause()
                    document.getElementById('part1').classList.add('off')
                },
                onEnterBack: () => {
                    document.querySelector('.video-container2').classList.remove('off')
                    document.querySelector('.video-container1 video').play()
                    document.getElementById('part1').classList.remove('off')
                },
                refreshPriority:90
            }
        })

        // 3
        gsap.to('#shape3 #forme1', {
            morphSVG:"#shape3 #forme2", 
            scaleX: window.innerWidth/159,
            scaleY: window.innerHeight/234,
            x:'0px',
            y:'0px',
            ease:'none',
            scrollTrigger: {
                id:'id3',
                trigger:'#part3 .flexHero',
                start:'top top',
                end:'+=' + window.innerHeight,
                scrub:true,
                pin:true,
                onLeave: () => {
                    document.querySelector('.video-container3').classList.add('off')
                    document.querySelector('.video-container2 video').pause()
                    document.getElementById('part2').classList.add('off')
                },
                onEnterBack: () => {
                    document.querySelector('.video-container3').classList.remove('off')
                    document.querySelector('.video-container2 video').play()
                    document.getElementById('part2').classList.remove('off')
                },
                refreshPriority: 86
            }
        })
        // if(!isTouch() && getTheOs() != 'Windows'){
        //     scroll.resize()
        // }
    }

    clearWebglAndEvent(){
        window.removeEventListener('resize', this.resize)
        this.webgl.destroyWebgl();
    }

    //this.mouseMove = this.eventMouseMove.bind(this);
    //window.addEventListener("mousemove", this.mouseMove);
    //window.removeEventListener("mousemove", this.mouseMove);
}