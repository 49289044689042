import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
// import {scroll} from "modules/constants"
import {lenis} from "./constants"
import {isTouch, getTheOs} from "modules/utils.js"


export class Faq{
    constructor(){

        gsap.to('.fakeH1 span', {
            y:'0%',
            autoAlpha:1,
            ease:'power3.out',
            stagger:0.1,
            duration:1.5,
            delay:0.8
        })

        // if(window.innerWidth > 900){
        //     gsap.to('.innerRetail', {
        //         y:'-' + window.innerHeight/2 + 'px',
        //         ease:'none',
        //         scrollTrigger: {
        //             trigger:'body',
        //             start:'top top',
        //             end:'+=' + window.innerHeight/2 + 'px',
        //             scrub: true,
        //         }
        //     })
        // }

        gsap.delayedCall(1, () => {
        //     if(!isTouch() && getTheOs() != 'Windows'){
        //         scroll.resize()
        //         scroll.wheel()
        //     }

            if(!isTouch()){
                lenis.start();
            }
        })

    }
}