import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
// import {scroll} from "modules/constants"
import {lenis} from "./constants"
import {isTouch, getTheOs} from "modules/utils.js"


export class How{
    constructor(){

        gsap.to('.fakeH1 span', {
            y:'0%',
            autoAlpha:1,
            ease:'power3.out',
            stagger:0.1,
            duration:1.5,
            delay:0.8
        })

        // if(window.innerWidth > 900){
        //     gsap.to('.innerRetail', {
        //         y:'-' + window.innerHeight/2 + 'px',
        //         ease:'none',
        //         scrollTrigger: {
        //             trigger:'.p-hero',
        //             start:'top top',
        //             end:'+=' + window.innerHeight/2 + 'px',
        //             scrub: true,
        //         }
        //     })
        // }
        if(document.querySelector('.h-sacs')){

            if(window.innerWidth >= 768){

                let distHero = 0.257
                if(window.innerWidth <= 1280){
                    distHero = 0.135
                }

                gsap.to('.masque', {
                    x:-distHero * window.innerWidth + 'px',
                    ease:'power2.inOut',
                    // immediateRender:false,
                    scrollTrigger: {
                        trigger:'.p-hero .droite',
                        start:'top top',
                        end: '+=1350',
                        scrub: true
                    }
                })

                gsap.to('.p-flex1 p', {
                    x:distHero * window.innerWidth + 'px',
                    ease:'power2.inOut',
                    scrollTrigger: {
                        trigger:'.p-hero .droite',
                        start:'top top',
                        end: '+=1350',
                        scrub: true
                    }
                })

                let distParts = 0.702
                if(window.innerWidth <= 1280){
                    distParts = 0.756
                }

                let btn = document.querySelectorAll('.p-parts button.actif')[0]
                let btn2 = document.querySelectorAll('.p-parts button.actif')[1]
                gsap.to('.p-parts', {
                    width:distParts * window.innerWidth + 'px',
                    x:-distHero * window.innerWidth + 'px',
                    ease:'power2.inOut',
                    onUpdate:() => {

                        const dist2Bouton = document.querySelectorAll('.p-parts button')[1].getBoundingClientRect().left - (document.querySelectorAll('.p-parts button')[0].getBoundingClientRect().left + document.querySelectorAll('.p-parts button')[0].offsetWidth)
                        const largeurBouton = btn.offsetWidth;
                        const widthProgress = largeurBouton + dist2Bouton/2

                        const largeurBouton2 = btn2.offsetWidth;
                        const widthProgress2 = largeurBouton2 + dist2Bouton

                        btn.querySelector('.progress').style.width = widthProgress + 'px'
                        btn2.querySelector('.progress').style.width = widthProgress2 + 'px'
                        btn2.querySelector('.progress').style.left = -dist2Bouton/2 + 'px'
                        
                    },
                    scrollTrigger: {
                        trigger:'.p-hero .droite',
                        start:'top top',
                        end: '+=1350',
                        scrub: true,
                        // onLeave:() => {
                        //     ScrollTrigger.refresh();
                        // }
                    }
                })

            }


            gsap.to('.p-hero .gauche img', {
                opacity:0.5,
                ease:'none',
                scrollTrigger: {
                    trigger:'.p-hero .droite',
                    start:'top top',
                    end: '+=1350',
                    scrub: true
                }
            })

        
            let currentProgress = 0;
            gsap.delayedCall(1, () => {

                ScrollTrigger.create({
                    trigger: ".h-sacs",
                    start: "top bottom", 
                    endTrigger:'.m-duo',
                    end:'top top',
                    onUpdate: self => {
                        let calc = Math.round(self.progress * 20); // 12 sac en tt
                        if(calc != currentProgress){
                            const el = document.querySelector('.unSac.current');

                            el.classList.remove('current');
                            if(el.nextElementSibling == null){
                                document.querySelectorAll('.unSac')[0].classList.add('current')
                            }else{
                                el.nextElementSibling.classList.add('current');
                            }

                            gsap.fromTo('.unSac.current', {
                                scale:1.08
                            },{
                                scale:1,
                                ease:'power4.out',
                                duration:0.2
                            })
                            currentProgress = calc;
                        }
                    }
                })

                if(window.innerWidth >= 768){

                    gsap.to('.innerSacs', {
                        y:'-10vh',
                        ease:'none',
                        scrollTrigger: {
                            trigger: ".h-derriere",
                            start: "top bottom", 
                            end:'bottom top',
                            scrub:true,
                        }
                    })
                    
                    gsap.to('.uneData2', {
                        y:'-200px',
                        ease:'none',
                        scrollTrigger: {
                            trigger:'.h-trio',
                            start:'top bottom',
                            end:'bottom top',
                            scrub:true
                        }
                    })
                    gsap.to('.uneData3', {
                        y:'-400px',
                        ease:'none',
                        scrollTrigger: {
                            trigger:'.h-trio',
                            start:'top bottom',
                            end:'bottom top',
                            scrub:true
                        }
                    })
                }

                if(window.innerWidth >= 900){
                    if(document.querySelector('.p-hori')){
                        gsap.to('header .toHome', {
                            y:'-100px',
                            ease:'none',
                            autoAlpha:0,
                            scrollTrigger: {
                                trigger: '.p-hori',
                                start:'top top+=100px',
                                end:'+=100px',
                                scrub:true
                            }
                        })
                    }
                }

                // HORI
                let ajout = 0;
                if(window.innerWidth <= 900){
                    ajout = 60
                }

                // A DECOMMENTER pour remettre scroll hori
                // const cont = document.querySelector('.container')
                // gsap.to('.container', {
                //     x:-1 * (cont.offsetWidth - window.innerWidth) + 'px',
                //     ease:'power1.inOut',
                //     scrollTrigger: {
                //         trigger:'.p-hori',
                //         start:'top top+=' + ajout,
                //         end: '+=' + (cont.offsetWidth - window.innerWidth) + 'px',
                //         scrub:true,
                //         pin:true
                //     }
                // })

                // document.querySelectorAll('.p-horiT').forEach(el => {
                //     gsap.to(el.querySelector('.traitHori'), {
                //         scaleY:1,
                //         opacity:0.2,
                //         duration:1.4,
                //         ease:'power4.out',
                //         scrollTrigger: {
                //             trigger:'.p-innerHori',
                //             start:'top top-=' + (el.getBoundingClientRect().left - 1*window.innerWidth + el.offsetWidth),
                //         }
                //     })
                // })

                gsap.to('header .toHome', {
                    y:'0px',
                    ease:'none',
                    immediateRender:false,
                    autoAlpha:1,
                    scrollTrigger: {
                        trigger: '.m-para',
                        start:'top top',
                        end:'+=100px',
                        scrub:true
                    }
                })

                gsap.to('.m-para img', {
                    y: - 1 * window.innerHeight - 100,
                    ease:'none',
                    scrollTrigger: {
                        trigger:'.m-para',
                        start:'top bottom',
                        end:'bottom top',
                        scrub:true,
                        pin:true,
                        pinSpacing:false
                    }
                })

                // if(!isTouch() && getTheOs() != 'Windows'){
                //     scroll.resize()
                //     scroll.wheel()
                // }

                if(!isTouch()){
                    lenis.start();
                }
            })
        }

        if(document.querySelector('.login')){
            document.getElementById('toLogIn').addEventListener('submit', (e) => {
                e.preventDefault();
                
                gsap.to('#toLogIn .message', {
                    autoAlpha:1,
                    duration:0.5,
                    delay:0.2,
                    ease:'power4.inOut'
                })
            })

            gsap.delayedCall(1, () => {
                if(!isTouch()){    
                    lenis.start();
                }
            })
        }


    }
}