import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
// import {scroll} from "modules/constants"
import {lenis} from "./constants"
import {isTouch, getTheOs} from "modules/utils.js"


export class Mission{
    constructor(){

        this.addTicker = () => {
            this.playTicker();
        }
        this.rotZ = gsap.quickSetter('#disk', "rotation", "deg");
        this.varia = 0;
        
        this.diff = this.distCur = this.distOld = 0;

        gsap.delayedCall(1, () => {
        //     if(!isTouch() && getTheOs() != 'Windows'){
        //         scroll.resize()
        //         scroll.wheel()
        //     }

            if(!isTouch()){
                lenis.start();
            }
        })

        gsap.delayedCall(0.6, () => {
            gsap.to('.img1 .paraImgOff', {
                y:0,
                ease:'power4.out',
                duration:1.4
            })
            
            // gsap.fromTo('.grosP', {
            //     opacity:0,
            // },{
            //     opacity:1,
            //     ease:'none',
            //     duration:1.4
            // })
        })


        // if(window.innerWidth > 900){
        //     gsap.to('.innerRetail', {
        //         y:'-' + window.innerHeight/2 + 'px',
        //         ease:'none',
        //         scrollTrigger: {
        //             trigger:'body',
        //             start:'top top',
        //             end:'+=' + window.innerHeight/2 + 'px',
        //             scrub: true,
        //         }
        //     })
        // }

        if(window.innerWidth > 767){

            gsap.to('.element', {
                width:0.325 * window.innerWidth + 'px',
                ease:'none',
                scrollTrigger:{
                    trigger:'.m-hero',
                    start:'top top',
                    endTrigger:'.innerTrio',
                    end:'bottom top',
                    scrub:true
                }
            })

            gsap.to('.img1, .img1 .numTrio, .img2 .numTrio, .img3 .numTrio', {
                scale:1,
                ease:'none',
                scrollTrigger:{
                    trigger:'.m-hero',
                    start:'top top',
                    endTrigger:'.innerTrio',
                    end:'bottom top',
                    scrub:true
                }
            })

            gsap.to('.img2, .img3', {
                x:0,
                scale:1,
                ease:'none',
                scrollTrigger:{
                    trigger:'.m-hero',
                    start:'top top',
                    endTrigger:'.innerTrio',
                    end:'bottom top',
                    scrub:true
                }
            })

            gsap.to('.petitP', {
                y:'-200%',
                x:0,
                ease:'none',
                scrollTrigger:{
                    trigger:'.m-hero',
                    start:'top top',
                    endTrigger:'.innerTrio',
                    end:'bottom top',
                    scrub:true
                }
            })

            gsap.to('.img2', {
                y:'-132px',
                ease:'none',
                scrollTrigger:{
                    trigger:'.m-hero',
                    start:'top top',
                    endTrigger:'.innerTrio',
                    end:'bottom top',
                    scrub:true
                }
            })
            gsap.to('.img3', {
                y:'0',
                ease:'none',
                scrollTrigger:{
                    trigger:'.m-hero',
                    start:'top top',
                    endTrigger:'.innerTrio',
                    end:'bottom top',
                    scrub:true
                }
            })
        }else{
            // console.log(document.querySelector('.m-hero .grosP').offsetHeight)
            gsap.delayedCall(0.1, () => {
                // on calcule la taille avec la déformation
                document.querySelector('.m-hero .element').style.height = document.querySelector('.m-hero .grosP').offsetHeight + 'px';
            })
            
            gsap.delayedCall(0.2, () => {
                // on reset la hauteur après l'ajout de la déformation
                document.querySelector('.m-hero .grosP').style.height = document.querySelector('.m-hero .grosP').offsetHeight + 'px';
            })

            gsap.delayedCall(0.3, () => {
                // on replace en bas la déformation
                document.querySelector('.m-hero .element').style.height = document.querySelector('.m-hero .grosP').offsetHeight + 'px';
                
                // gsap.to('.element', {
                //     width:0.35 * window.innerWidth + 'px',
                //     ease:'none',
                //     scrollTrigger:{
                //         trigger:'.m-hero',
                //         start:'top top',
                //         endTrigger:'.img1',
                //         end:'bottom top',
                //         scrub:true
                //     }
                // })

                // gsap.to('.img1', {
                //     scale:0.72,
                //     ease:'none',
                //     scrollTrigger:{
                //         trigger:'.m-hero',
                //         start:'top top',
                //         endTrigger:'.img1',
                //         end:'bottom top',
                //         scrub:true
                //     }
                // })
                // gsap.to('.img1 .numTrio', {
                //     scale:1.28,
                //     ease:'none',
                //     scrollTrigger:{
                //         trigger:'.m-hero',
                //         start:'top top',
                //         endTrigger:'.img1',
                //         end:'bottom top',                  
                //         scrub:true
                //     }
                // })



            })

            // gsap.delayedCall(1, () => {
            //     document.querySelector('.img1 .paraImgOff').style.overflow = "hidden"
            // })

            // window.addEventListener('scroll', () => {
            //     document.querySelector('.img1 .paraImgOff').style.overflow = "hidden";
            //     document.querySelector('.img1 .paraImgOff').style.display = "block";
            // })

            
        }

        ScrollTrigger.create({
            trigger:'.m-disk',
            start:'top bottom',
            end:'bottom top',
            onEnter: () => {
                console.log('on')
                this.lancerRaf();
            },
            onEnterBack: () => {
                console.log('on')
                this.lancerRaf();
            },
            onLeave: () => {
                console.log('off')
                this.killRaf();
            },
            onLeaveBack: () => {
                console.log('off')
                this.killRaf();
            }
        })

        gsap.to('#rotate', {
            rotation:-360,
            duration:1.4,
            // ease:'power4.inOut',
            // ease:"elastic.out(1, 0.3)",
            ease:"elastic.inOut(1.2, 1)",
            repeat:-1,
            repeatDelay:2,
            scrollTrigger: {
                trigger:'#rotate',
                start:'top 50%',
            }
        })

        gsap.to('.m-para img', {
            y: - 1 * window.innerHeight - 100,
            ease:'none',
            scrollTrigger: {
                trigger:'.m-para',
                start:'top bottom',
                end:'bottom top',
                scrub:true,
                pin:true,
                pinSpacing:false
            }
        })

    }

    playTicker() {

        // console.log('ONNNN')

        const gDt = gsap.ticker.deltaRatio();
        const dt = 1.0 - Math.pow(1.0 - 0.1, gDt);

        this.varia -= gDt/7 + this.diff * dt;
        this.rotZ(this.varia)

        // part delta au scroll
        this.distCur = window.pageYOffset;
        this.diff = (this.distCur - this.distOld)/2;
        this.distOld = this.distCur;

    }

    lancerRaf() {
        gsap.ticker.add(this.addTicker);
    }

    killRaf() {
        gsap.ticker.remove(this.addTicker);
    }
}