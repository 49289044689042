import gsap from "gsap"
import * as THREE from "three"

export class WebglProcess{
    constructor(){
        
        this.renderer = new THREE.WebGLRenderer( { antialias: true } )
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        // this.renderer.setPixelRatio(0.5)

        this.diff = this.distCur = this.distOld = 0;


        // let W = 0.8 * window.innerWidth;
        let W = window.innerWidth;

        const H = document.querySelector('.uneStep').offsetHeight;

        this.renderer.setSize( W, H );
        document.getElementById('webgl').appendChild( this.renderer.domElement );

        let frustumSize = H;
        let aspect = W/H;

        this.camera = new THREE.OrthographicCamera(
            frustumSize * aspect / - 2,
            frustumSize * aspect / 2,
            frustumSize / 2,
            frustumSize / -2,
            -1000,
            1000
        )
        // this.camera.position.y = 1.5 * window.innerHeight;

        // FORMAT VIDEO = 1920 * 1080
        // ratio 1.77

        this.video1 = document.getElementById( 'video1' );
        this.video1.loop = this.video1.muted = true;
        this.video1.play();
        const texture1 = new THREE.VideoTexture( this.video1 );
        texture1.matrixAutoUpdate = false;

        this.video2 = document.getElementById( 'video2' );
        this.video2.loop = this.video2.muted = true;
        this.video2.play();
        const texture2 = new THREE.VideoTexture( this.video2 );
        texture2.matrixAutoUpdate = false;

        this.video3 = document.getElementById( 'video3' );
        this.video3.loop = this.video3.muted = true;
        this.video3.play();
        const texture3 = new THREE.VideoTexture( this.video3 );
        texture3.matrixAutoUpdate = false;

        this.video4 = document.getElementById( 'video4' );
        this.video4.loop = this.video4.muted = true;
        this.video4.play();
        const texture4 = new THREE.VideoTexture( this.video4 );
        texture4.matrixAutoUpdate = false;

        

        this.addTicker = () => {
            this.playTicker();
        }
        
        

        // let width = 0.55 * window.innerWidth;
        // let width = 0.8 * window.innerWidth;
        let width = window.innerWidth;
        // let height = window.innerHeight;
        let height = document.querySelector('.uneStep').offsetHeight;
        let aspectGeo = width/height;


        let geometry = new THREE.PlaneGeometry( width, height, 1, 1 );

        this.cover( texture1, 1920, 1080, aspectGeo )
        this.cover( texture2, 1920, 1080, aspectGeo )
        this.cover( texture3, 1920, 1080, aspectGeo )
        this.cover( texture4, 1920, 1080, aspectGeo )

        
        const material1 = new THREE.MeshBasicMaterial({
            map:texture1
        });
        const material2 = new THREE.MeshBasicMaterial({
            map:texture2
        });
        const material3 = new THREE.MeshBasicMaterial({
            map:texture3
        });
        const material4 = new THREE.MeshBasicMaterial({
            map:texture4
        });

        let ajout = 0;
        if(window.innerWidth <= 900){
            ajout = 60;
        }

        this.vid1 = new THREE.Mesh( geometry, material1 );
        this.vid1.position.y = -height + ajout;

        this.vid2 = new THREE.Mesh( geometry, material2 );
        this.vid2.position.y = -2*height + ajout;

        this.vid3 = new THREE.Mesh( geometry, material3 );
        this.vid3.position.y = -3*height + ajout;

        this.vid4 = new THREE.Mesh( geometry, material4 );
        this.vid4.position.y = -4*height + ajout;
        
        this.scene = new THREE.Scene();
        this.scene.background = new THREE.Color( 0x1F0808 );

        
        this.scene.add(
            this.vid1,
            this.vid2, 
            this.vid3, 
            this.vid4
        )

        this.renderer.render(this.scene, this.camera)
        

        //
        // partie post process
        //

        this.rendererP = this.renderer;
        this.sceneP = new THREE.Scene();
        
        this.dummyCameraP = new THREE.OrthographicCamera();
        const geometryP = new THREE.BufferGeometry();

        // Triangle expressed in clip space coordinates
        const vertices = new Float32Array([
          -1.0, -1.0,
          3.0, -1.0,
          -1.0, 3.0
        ]);

        geometryP.setAttribute('position', new THREE.BufferAttribute(vertices, 2));

        const resolutionP = new THREE.Vector2();
        this.rendererP.getDrawingBufferSize(resolutionP);

        this.targetP = new THREE.WebGLRenderTarget(resolutionP.x, resolutionP.y, {
            format: THREE.RGBFormat,
            stencilBuffer: false,
            depthBuffer: true,
        });

        
        this.materialP = new THREE.RawShaderMaterial({
            vertexShader: `
                precision highp float;
                attribute vec2 position;

                //varying vec2 vPosition;

                void main() {
                    gl_Position = vec4(position, 1.0, 1.0);
                }
            `,
            fragmentShader: `
                precision highp float;
                uniform sampler2D uScene;
                uniform vec2 uResolution;
                uniform float uIntensite;

                //varying vec2 vPosition;

                void main() {
                    vec2 uv = gl_FragCoord.xy / uResolution.xy;

                    // base
                    uv.x += sin((uv.y - 0.5) * 3. + 3.14/2. ) / 14. * ((uv.x - 0.5) * 4.) * uIntensite/3.;
                    // uv.x += sin((uv.y - 0.5) * 3. + 3.14/2. ) / 14. * ((uv.x * 3./2.) - 1.) * uIntensite;

                    gl_FragColor = texture2D(uScene, uv);
                }
            `,
            uniforms: {
                uScene: { value: this.targetP.texture },
                uResolution: { value: resolutionP },
                uIntensite: {value: 0}
            },
        });

        


        // TODO: handle the resize -> update uResolution uniform and this.target.setSize()

        const triangleP = new THREE.Mesh(geometryP, this.materialP);
        // Our triangle will be always on screen, so avoid frustum culling checking
        triangleP.frustumCulled = false;
        this.sceneP.add(triangleP);

        //
        // traitements
        //
        this.ySet = gsap.quickSetter(this.camera.position, "y");
        this.posY = 0;
        this.varia = 0;
        this.deltaY = 0;

        this.inten = gsap.quickSetter(this.materialP.uniforms.uIntensite, "value");

    }

    lancerRaf() {
        gsap.ticker.add(this.addTicker);
    }

    killRaf() {
        gsap.ticker.remove(this.addTicker);
    }

    playTicker() {

        const gDt = gsap.ticker.deltaRatio();
        const dt = 1.0 - Math.pow(1.0 - 0.1, gDt);//0.1 = speed

        //if(document.querySelector('.partieSticky').getBoundingClientRect().top < -window.innerHeight/2){
        this.ySet(document.querySelector('.p-steps').getBoundingClientRect().top);
        //}

        
        if(Math.abs(this.varia/25) > 1.5){ // /40
            this.inten(1.5)
        }else{ // /40
            this.inten(Math.abs(this.varia/25)) // /40
        }


        // this.varia += (this.deltaY/2 - this.varia) * dt;
        this.varia += (this.diff/2 - this.varia) * dt;

        // optim ?
        //if(Math.abs(this.varia) > 0.05){
        //    this.renderer.setPixelRatio(0.5)
        //    this.rendererP.setPixelRatio(0.5)
        //}else{
        //    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        //    this.rendererP.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        //}

        this.rendererP.setRenderTarget(this.targetP);
        this.rendererP.render(this.scene, this.camera);
        this.rendererP.setRenderTarget(null);
        this.rendererP.render(this.sceneP, this.dummyCameraP);



        // part delta au scroll
        this.distCur = window.pageYOffset;
        this.diff = this.distCur - this.distOld;
        this.distOld = this.distCur;

    }

    cover( texture, textureW, textureH, aspect ) {

        let imageAspect = textureW / textureH;

        if ( aspect < imageAspect ) {
            texture.matrix.setUvTransform( 0, 0, aspect / imageAspect, 1, 0, 0.5, 0.5 );
        } else {
            texture.matrix.setUvTransform( 0, 0, 1, imageAspect / aspect, 0, 0.5, 0.5 );
        }
    }

    destroyWebgl() {
        this.killRaf();
        this.renderer.clear()
        this.rendererP.clear()
    }

}