// lib
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger"
// import {isTouch, getTheOs} from "utils.js"
import {isTouch} from "utils.js"

import {Home} from "Home"
import {Process} from "Process"
import {Mission} from "Mission"
import {How} from "How"
import {Faq} from "Faq"
import {Ressources} from "Ressources"
import {Terms} from "Terms"
import {T404} from "T404"
import {Authentification} from "Authentification"
import {Global} from "Global"
// import {scroll, monLottie} from "./constants"
import {monLottie, lenis} from "./constants"


export class Transition
{
    constructor() {
        this.currentStep = 0;
        this.allSteps = 2; // anim sorti + nouveau dom loadé

        this.newClass;
        this.newTitle;
        this.newDOM;

        this.animEnCours = false;
        this.first = true;

        monLottie.addEventListener('DOMLoaded', this.voletReady);

        // raf lenis
        this.rafLenis = this.rafLenis.bind(this)
    }

    start (url) {
        console.log('transition start');
        this.animEnCours = true;
        this.changementDOM(url);
        this.animationSortie();
    }

    // STEP
    animationSortie() {

        // if(!isTouch() && getTheOs() != 'Windows'){
        //     scroll.unwheel();
        // }

        gsap.to('#main', {
            opacity:0,
            ease:'power2.inOut',
            duration:0.3,
            onComplete:() => {

                if(this.home != undefined && document.body.classList.contains('home')){
                    this.home.clearWebglAndEvent();
                }else if(this.process != undefined && document.body.classList.contains('process')){
                    this.process.clearWebgl();
                }else if(this.mission != undefined && document.body.classList.contains('mission')){
                    this.mission.killRaf();
                }

                this.killAllSTrigger();
                this.incrementStep();
                // if(!isTouch() && getTheOs() != 'Windows'){
                //     scroll.current = scroll.scrollTop = 0;
                // }

                if(lenis){
                    lenis.stop()
                    gsap.ticker.remove(this.rafLenis)
                }
            }
        })

    }

    killAllSTrigger() {
        let triggers = ScrollTrigger.getAll();
        triggers.forEach( trigger => {
            trigger.kill();
        });
    }

    // STEP
    changementDOM(url) {
        this.ajaxPromise(url).then((data) => {
            let parser = new DOMParser();
            let doc = parser.parseFromString(data, "text/html");
            
            // maj titre de la page
            this.newTitle = doc.querySelector('title').innerHTML;
            this.newClass = doc.body.getAttribute('class');
            this.newDOM = doc.getElementById('main').innerHTML;

            this.incrementStep();  
        });
    }

    // ALL STEPS COMPLETE
    animationEntree() {


        //reset
        window.scrollTo(0,0);
        this.currentStep = 0;

        if(lenis){
            lenis.scrollTo(0, { immediate: true })
        }

        document.title = this.newTitle;
        document.body.setAttribute('class', this.newClass);
        document.getElementById('main').innerHTML = this.newDOM;

        // if(getTheOs() == 'Windows'){
        //     document.body.classList.add('windows')
        // }
        
        this.animEnCours = false;

        if(document.body.classList.contains('home')){
            this.home = new Home();
        }else if(document.body.classList.contains('process')){
            this.process = new Process();
        }else if(document.body.classList.contains('mission')){
            this.mission = new Mission();
        }else if(document.body.classList.contains('how')){
            this.how = new How();
        }else if(document.body.classList.contains('faq')){
            this.faq = new Faq();
        }else if(document.body.classList.contains('ressources')){
            this.ressources = new Ressources();
        }else if(document.body.classList.contains('terms')){
            this.terms = new Terms();
        }else if(document.body.classList.contains('authentification')){
            this.authentification = new Authentification();
        }else if(document.body.classList.contains('t404')){
            this.t404 = new T404();
        }

        this.global = new Global();

        gsap.delayedCall(0.6, () => {
            monLottie.goToAndStop(95, true);
            monLottie.playSegments([95, 191], true);

            document.getElementById('lottie').classList.add('lance')
            gsap.set('#main', {
                opacity:1
            })

            if(lenis){
                gsap.ticker.add(this.rafLenis)
                //lenis.start()
            }


        })

        // gsap.to('#main', {
        //     opacity:1,
        //     ease:'power2.inOut',
        //     duration:1,
        // });
        
    }



    //
    //
    // util class
    //
    //
    incrementStep() {
        this.currentStep++;
        if(this.currentStep == this.allSteps) {
            this.animationEntree();
        }
    }

    ajaxPromise (url) {
        return new Promise((resolve) => { //, reject
            const xhr = new XMLHttpRequest();
            xhr.open("GET", url);
            xhr.onload = () => resolve(xhr.responseText);
            xhr.send();
        });
    }

    rafLenis(time, dt) {
        lenis.raf(time * 1000)
    }

    //
    voletReady () {
        document.querySelector('#lottie svg').setAttribute('preserveAspectRatio', 'none');
    }
}