// libs
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger" 
import MorphSVGPlugin from "gsap/MorphSVGPlugin";
gsap.registerPlugin(ScrollTrigger, MorphSVGPlugin);



// import {getTheOs, isTouch} from "modules/utils.js"
import {isTouch} from "modules/utils.js"
import {lenis} from "modules/constants"

import {Transition} from "modules/Transition"



history.scrollRestoration = "manual";
window.onbeforeunload = function () {
    window.scrollTo(0, 0);
}

const transition = new Transition();
window.addEventListener("DOMContentLoaded", () => {

    // console.log('the nombre de core is:', navigator.hardwareConcurrency)
    // console.log('the os is:', getTheOs());
    
    transition.start(window.location.href);

    let ouvertureEnCours;
    document.addEventListener('click', (e) => {

        if (
            event.target.closest('a') && 
            !event.target.closest('a').classList.contains('toArticle') &&
            !event.target.closest('a').classList.contains('toRessource') &&
            !event.target.closest('a').classList.contains('toBlank')
        ) {

            const elDom = event.target.closest('a');
            event.preventDefault();

            if(!transition.animEnCours){
                transition.start(elDom.getAttribute('href'));
                history.pushState({}, '', elDom.getAttribute('href'));
            }
            
        }else if (e.target.closest('.toScroll')){
            // const progress = {y:-scroll.current}
            const progress = {y:window.pageYOffset}

            gsap.to(progress, {
                y:window.pageYOffset + document.querySelector('.contentPart1').getBoundingClientRect().top - (0.5 * window.innerHeight) + document.querySelector('.contentPart1 .grosP').offsetHeight/2,
                ease:'power2.inOut',
                duration:1.6,
                onUpdate:() => {
                    window.scrollTo(0, progress.y)
                    // if(!isTouch() && getTheOs() != 'Windows'){
                    //     scroll.scrollTop = progress.y;
                    //     scroll.current = -progress.y; // IMPORTANT
                    // }
                }
            })

        // PROGRESS BAR
        }else if (e.target.closest('button[data-element]')){

            let ajout = 0;
            if(document.body.classList.contains('authentification') && window.innerWidth < 768){
                ajout = 100;
            }

            // if(e.target.closest('button[data-element]').parentNode.classList.contains('p-partsMob')){
            //     const parent = e.target.closest('button[data-element]').parentNode;
            //     parent.querySelector('button.current').classList.remove('current')
            //     e.target.closest('button[data-element]').classList.add('current')
            // }

            const el = e.target.closest('button[data-element]');
            // const progress = {y:-scroll.current}
            // const progress = {y:window.pageYOffset}

            // gsap.to(progress, {
            //     y:document.querySelector('.' + el.getAttribute('data-element')).getBoundingClientRect().top + window.pageYOffset - ajout,
            //     ease:'power3.inOut',
            //     duration:1,
            //     onUpdate:() => {
            //         window.scrollTo(0, progress.y)
            //         // if(!isTouch() && getTheOs() != 'Windows'){
            //         //     scroll.scrollTop = progress.y;
            //         //     scroll.current = -progress.y; // IMPORTANT
            //         // }
            //     }
            // })

            if(lenis){
                lenis.scrollTo(document.querySelector('.' + el.getAttribute('data-element')).getBoundingClientRect().top + window.pageYOffset - ajout)
            }

        //
        }else if (e.target.closest('.toMenu') && !ouvertureEnCours){
            ouvertureEnCours = true;
            document.getElementById('fondMenu').classList.add('on')
            // gsap.to('#innerMenu, #menu', {
            //     y:0,
            //     ease:'power4.inOut',
            //     duration:0.8
            // })

            gsap.set('#menu', {autoAlpha:1})
            gsap.to('#menu', {
                "clip-path": "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
                ease:'power4.inOut',
                duration:0.8,
                onComplete:() => {
                    ouvertureEnCours = false;
                }
            })

            gsap.killTweensOf('#menu .part2 li')
            gsap.fromTo('#menu .part2 li', {
                y:'-20%',
                autoAlpha:0,
            }, {
                y:'0%',
                autoAlpha:1,
                ease:'power2.out',
                duration:1,
                stagger:0.07,
                delay:0.4
            })

            // if(!isTouch() && getTheOs() != 'Windows'){
            //     //scroll.unwheel();
            // }else{
            //     document.body.classList.add('hidd')
            //     document.body.style.height = window.innerHeight + 'px'
            // }

        }else if ((e.target.closest('.closeMenu') || e.target.closest('#fondMenu')) && !ouvertureEnCours){
            ouvertureEnCours = true;
            document.getElementById('fondMenu').classList.remove('on')
            // gsap.to('#innerMenu', {
            //     y:'-100%',
            //     ease:'power4.inOut',
            //     duration:0.8
            // })
            // gsap.to('#menu', {
            //     y:'100%',
            //     ease:'power4.inOut',
            //     duration:0.8
            // })

            gsap.to('#menu', {
                "clip-path": "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
                ease:'power4.inOut',
                duration:0.8,
                onComplete:() => {
                    ouvertureEnCours = false;
                    gsap.set('#menu', {autoAlpha:0})
                }
            })

            // if(!isTouch() && getTheOs() != 'Windows'){
            //     //scroll.wheel();
            // }else{
            //     document.body.classList.remove('hidd')
            //     document.body.style.height = 'auto'
            // }

        }else if (e.target.closest('.uneQuestion')) {
            const el = e.target.closest('.uneQuestion');
            el.classList.toggle('actif');

            document.querySelectorAll('.uneQuestion').forEach(quest => {
                if(
                    quest.classList.contains('actif') && quest != el ||
                    quest == el && !el.classList.contains('actif') 
                ){
                    quest.classList.remove('actif')
                    gsap.to(quest, {
                        height:el.querySelector('.haut').offsetHeight,
                        duration:0.5,
                        ease:'power4.inOut',
                        onComplete:() => {
                            // if(!isTouch() && getTheOs() != 'Windows'){
                            //     scroll.resize();
                            // }
                            ScrollTrigger.refresh();
                        }
                    })
                }
            })

            if(el.classList.contains('actif')){
                gsap.to(el, {
                    height:el.querySelector('.bas').offsetHeight + el.querySelector('.haut').offsetHeight,
                    duration:0.5,
                    ease:'power4.inOut',
                    onComplete:() => {
                        // if(!isTouch() && getTheOs() != 'Windows'){
                        //     scroll.resize();
                        // }
                        ScrollTrigger.refresh();
                    }
                })
            }  
        }
    })

    // if(!isTouch() && getTheOs() != 'Windows'){
    //     scroll.init();
    // }


    // if(document.body.classList.contains('home')){
    //     const home = new Home();
    // }else if(document.body.classList.contains('process')){
    //     const process = new Process();
    // }



    



    
    
})


window.addEventListener('popstate', () => {
    transition.start(location.href);
}, false);