// libs
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import {indexInParent} from "utils"

import {isTouch, getTheOs} from "modules/utils.js"
// import {scroll} from "modules/constants"


export class Global
{
    constructor() {
        
        gsap.delayedCall(1, () => {

            //
            //
            // changement couleur logo header
            //
            //
            if(window.innerWidth > 900){
                document.querySelectorAll('.logoInvers').forEach(el => {
                    ScrollTrigger.create({
                        trigger:el,
                        start:'top top+=1',
                        end:'bottom top',
                        onEnter: () => {document.querySelector('header .toHome').classList.add('invert')},
                        onLeave: () => {document.querySelector('header .toHome').classList.remove('invert')},
                        onEnterBack: () => {document.querySelector('header .toHome').classList.add('invert')},
                        onLeaveBack: () => {document.querySelector('header .toHome').classList.remove('invert')},
                    })
                })
            }

            //
            //
            // megatitre
            //
            //
            document.querySelectorAll('.megaTitre').forEach(el => {

                if(document.querySelector('.lettre')){
                    gsap.to(el.querySelectorAll('.lettre'), {
                        autoAlpha:1,
                        x:'0%',
                        ease:'power4.out',
                        duration:1,
                        stagger:0.05,
                        scrollTrigger: {
                            trigger: el,
                            start:'top 60%',
                        }
                    })
                }
           })


            //
            //
            //
            //
            document.querySelectorAll('.pSpe:not(.pSpeHori)').forEach(el => {
                gsap.fromTo(el, {
                    opacity:0,
                    y:'60px'
                },{
                    opacity:1,
                    y:'0px',
                    duration:1,
                    ease:'power2.inOut',
                    scrollTrigger: {
                        trigger:el,
                        start:'top bottom'
                    }
                })
            })
            document.querySelectorAll('.preTitre:not(.preTHori)').forEach(el => {
                gsap.fromTo(el, {
                    opacity:0,
                    // y:'150%'
                },{
                    // y:'0%',
                    opacity:1,
                    duration:1,
                    delay:0.4,
                    ease:'power2.inOut',
                    scrollTrigger: {
                        trigger:el,
                        start:'top bottom'
                    }
                })
            })



            //
            //
            // parallax img mask
            //
            //
            document.querySelectorAll('.paraImg').forEach(el => {
                gsap.to(el.querySelector('img'), {
                    y:'0px',
                    ease:'none',
                    scrollTrigger:{
                        trigger:el,
                        start:'top bottom',
                        end:'bottom top',
                        scrub:true
                    }
                })
            })


            //
            //
            // footer
            //
            //
            if(document.querySelector('footer')){
                ScrollTrigger.create({
                    trigger:'footer',
                    start:'top bottom',
                    // onEnter: () => {
                    //     if(!isTouch() && getTheOs() != 'Windows'){
                    //         scroll.resize()
                    //     }
                    // }
                });
                ScrollTrigger.create({
                    trigger:'footer',
                    start:'top top',
                    onEnter: () => {
                        document.getElementById('fondFooter').classList.add('on');
                    },
                    onLeaveBack: () => {
                        document.getElementById('fondFooter').classList.remove('on');
                    }
                })
                gsap.to('#fondFooter img', {
                    y:0,
                    ease:'none',
                    scrollTrigger: {
                        trigger:'.vide',
                        start:'top bottom',
                        end:'bottom bottom',
                        scrub:0.4,
                    }
                })


                ScrollTrigger.create({
                    trigger:'.vide',
                    start:'top bottom+=40', // cause bar du foooter ios
                    onEnter:() => {
                        document.querySelector('#fondFooter .infos').classList.add('on');
                    },
                    onLeaveBack:() => {
                        document.querySelector('#fondFooter .infos').classList.remove('on');
                    }
                })
            }


            //
            //
            // progress bar
            //
            //
            if(document.querySelector('.p-parts')){
                let dist2Bouton = document.querySelectorAll('.p-parts button')[1].getBoundingClientRect().left - (document.querySelectorAll('.p-parts button')[0].getBoundingClientRect().left + document.querySelectorAll('.p-parts button')[0].offsetWidth)
                
                document.querySelectorAll('.p-parts button.actif').forEach(el => {

                    if(window.innerWidth > 767){
                        const largeurBouton = el.offsetWidth;

                        let widthProgress = 0
                        // si premier
                        if(indexInParent(el) == 0){
                            widthProgress = largeurBouton + dist2Bouton/2
                            el.querySelector('.progress').style.width = widthProgress + 'px'

                        // si dernier
                        }else if(indexInParent(el) == document.querySelectorAll('.p-parts button').length-1){
                            widthProgress = largeurBouton + dist2Bouton/2
                            el.querySelector('.progress').style.width = widthProgress + 'px'
                            el.querySelector('.progress').style.left = -dist2Bouton/2 + 'px'

                        // si entouré de deux autres boutons
                        }else {
                            widthProgress = largeurBouton + dist2Bouton
                            el.querySelector('.progress').style.width = widthProgress + 'px'
                            el.querySelector('.progress').style.left = -dist2Bouton/2 + 'px'
                        }

                        gsap.to(el.querySelector('.progress span'), {
                            scaleX:1,
                            ease:'none',
                            scrollTrigger: {
                                trigger: '.' + el.getAttribute('data-element'),
                                start:'top top',
                                end:'bottom top',
                                scrub: true,
                            }
                        })
                    }else{

                        let ajout = 0;
                        if(document.body.classList.contains('authentification')){
                            ajout = 100;
                        }

                        ScrollTrigger.create({
                            trigger: '.' + el.getAttribute('data-element'),
                            start:'top top+=' + (1 + ajout),
                            end:'bottom top+=' + (1 + ajout),
                            scrub: true,
                            onLeave:() => {document.querySelectorAll('.p-partsMob button')[indexInParent(el)].classList.remove('current')},
                                    onLeaveBack:() => {
                                if(indexInParent(el) != 0){
                                    document.querySelectorAll('.p-partsMob button')[indexInParent(el)].classList.remove('current');
                                }
                            },
                            onEnter:() => {document.querySelectorAll('.p-partsMob button')[indexInParent(el)].classList.add('current')},
                            onEnterBack:() => {document.querySelectorAll('.p-partsMob button')[indexInParent(el)].classList.add('current')}
                        })
                    }
                })
            }

        })
    }
}