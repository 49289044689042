import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
// import {scroll} from "modules/constants"
import {lenis} from "./constants"
import {isTouch, getTheOs} from "modules/utils.js"


export class Authentification{
    constructor(){

        gsap.to('.h1Spe span', {
            y:'0%',
            autoAlpha:1,
            ease:'power3.out',
            stagger:0.1,
            duration:1.5,
            delay:0.8
        })

        document.querySelectorAll('.carousel figure:not(.current)').forEach(el => {
            el.style.height = el.parentNode.querySelector('figure.current').offsetHeight + 'px'
        })

        // if(window.innerWidth > 900){
        //     gsap.to('.innerRetail', {
        //         y:'-' + window.innerHeight/2 + 'px',
        //         ease:'none',
        //         scrollTrigger: {
        //             trigger:'body',
        //             start:'top top',
        //             end:'+=' + window.innerHeight/2 + 'px',
        //             scrub: true,
        //         }
        //     })
        // }

        // if(!isTouch() && getTheOs() != 'Windows'){
        //     gsap.delayedCall(1, () => {
        //         scroll.resize()
        //         scroll.wheel()
        //     })
        // }

        gsap.delayedCall(1, () => {
            if(!isTouch()){
                lenis.start();
            }
        })

        if(document.querySelector('.carousel')){
            document.querySelectorAll('.carousel').forEach(carou => {
                new Carou(carou)
            })
        }

    }
}


class Carou{
    constructor(el){
        this.el = el;
        this.innerPuces = el.querySelector('.navCarou')
        this.zIndex = 1;
        this.currentSlide = 0;
        this.animEnCours = false;

        this.el.querySelectorAll('img').forEach(img => {
            img.addEventListener('click', () => {

                if(!this.animEnCours){
                    this.animEnCours = true;

                    this.zIndex++;
                    this.currentSlide = (this.currentSlide + 1) % this.el.querySelectorAll('figure').length;

                    gsap.set(this.el.querySelector('figure.current'), {autoAlpha:1})
                    gsap.to(this.el.querySelector('figure.current figcaption'), {
                        opacity:0,
                        duration:0.3
                    })
                    this.el.querySelector('figure.current').classList.remove('current')
                    this.el.querySelectorAll('figure')[this.currentSlide].classList.add('current')

                    // puces
                    this.innerPuces.querySelector('button.current').classList.remove('current')
                    this.innerPuces.querySelectorAll('button')[this.currentSlide].classList.add('current')
                    //

                    this.el.querySelector('figure.current').style.zIndex = this.zIndex;

                    gsap.fromTo(this.el.querySelector('figure.current'), {
                        "clip-path": "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)",
                        autoAlpha:1
                    }, {
                        "clip-path": "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
                        duration:0.5, ease:'power4.inOut',
                        delay:0.2 
                    })
                    gsap.fromTo(this.el.querySelector('figure.current figcaption'), {
                        opacity:0
                    }, {
                        opacity:1,
                        duration:0.3, ease:'power4.inOut',
                        delay:0.7,
                        onComplete:() => {
                            this.animEnCours = false;
                        }
                    })
                }

            })
        })
    }
}