import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
// import {scroll} from "modules/constants"
import {lenis} from "./constants"
import {isTouch, getTheOs} from "modules/utils.js"


export class T404{
    constructor(){

        this.addTicker = () => {
            this.playTicker();
        }
        this.rotZ = gsap.quickSetter('#disk', "rotation", "deg");
        this.varia = 0;
        
        this.diff = this.distCur = this.distOld = 0;

        this.wheel = 0;
        window.addEventListener('wheel', (e) => {
            this.wheel = e.deltaY

            window.clearTimeout( this.isWheeling );
            this.isWheeling = setTimeout( (e) => {
                this.wheel = 0;
            }, 66);
        })

        gsap.delayedCall(1, () => {
        //     if(!isTouch() && getTheOs() != 'Windows'){
        //         scroll.resize()
        //         scroll.wheel()
        //     }

            if(!isTouch()){
                lenis.start();
            }
        })

        

        ScrollTrigger.create({
            trigger:'.m-disk',
            start:'top bottom',
            end:'bottom top',
            onEnter: () => {
                console.log('on')
                this.lancerRaf();
            },
            onEnterBack: () => {
                console.log('on')
                this.lancerRaf();
            },
            onLeave: () => {
                console.log('off')
                this.killRaf();
            },
            onLeaveBack: () => {
                console.log('off')
                this.killRaf();
            }
        })

        gsap.to('#rotate', {
            rotation:-360,
            duration:1.4,
            // ease:'power4.inOut',
            // ease:"elastic.out(1, 0.3)",
            ease:"elastic.inOut(1.2, 1)",
            repeat:-1,
            repeatDelay:2,
            scrollTrigger: {
                trigger:'#rotate',
                start:'top 50%',
            }
        })

        

    }

    playTicker() {

        // console.log('ONNNN')

        const gDt = gsap.ticker.deltaRatio();
        const dt = 1.0 - Math.pow(1.0 - 0.1, gDt);

        this.varia -= gDt/7 + this.diff * dt;
        this.rotZ(this.varia)

        // part delta au scroll
        // this.distCur = window.pageYOffset;
        this.diff = this.wheel / 10;
        // this.distOld = this.distCur;

    }

    lancerRaf() {
        gsap.ticker.add(this.addTicker);
    }

    killRaf() {
        gsap.ticker.remove(this.addTicker);
    }
    
}