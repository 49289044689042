// import GScroll from "GScroll"
import Lottie from "lottie-web"
import Lenis from '@studio-freight/lenis'
import { isTouch } from 'utils'

// export const scroll = new GScroll('body', 0.18);

export const monLottie = Lottie.loadAnimation({
    container: document.getElementById('lottie'), // the dom element that will contain the animation
    renderer: 'svg',
    autoplay: false,
    loop:false,
    path: document.getElementById('lottie').getAttribute('data-url') // the path to the animation json
});

//SMOOTH SCROLL
//SMOOTH SCROLL
//SMOOTH SCROLL
export const lenis = !isTouch() ? new Lenis({
    duration: 2,
    easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
}) : null;
if(lenis){
    lenis.stop()
}