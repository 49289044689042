import gsap from "gsap"
import * as THREE from "three"

export class Webgl{
    constructor(){

        console.log('constructor gl')
        
        this.renderer = new THREE.WebGLRenderer( { antialias: true } )
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        // this.renderer.setPixelRatio(2)

        this.diviseur = 25;
        if(window.innerWidth < 768){
            this.diviseur = 10;
        }

        this.diff = this.distCur = this.distOld = 0;


        this.W = window.innerWidth
        this.H = window.innerHeight
        this.renderer.setSize( this.W, this.H );

        document.getElementById('webgl').appendChild( this.renderer.domElement );

        this.frustumSize = this.H;
        this.aspect = this.W/this.H;

        this.camera = new THREE.OrthographicCamera(
            this.frustumSize * this.aspect / - 2,
            this.frustumSize * this.aspect / 2,
            this.frustumSize / 2,
            this.frustumSize / -2,
            -1000,
            1000
        )
        this.camera.position.y = ((1208 * window.innerWidth) / 1422) / 2 + 0.5 * window.innerHeight;
        
        // const textureLoader = new THREE.TextureLoader()
        // const texture = textureLoader.load('./img/sac2.jpg')

        this.video = document.getElementById( 'videoGL' );
        this.video.loop = this.video.muted = true;
        this.video.play();
        const texture = new THREE.VideoTexture( this.video );
        texture.matrixAutoUpdate = false;

        // texture.wrapS = THREE.RepeatWrapping;
        // texture.wrapT = THREE.RepeatWrapping;


        // 1422 -> window.innerWidth
        // 1208 ->

        this.addTicker = () => {
            this.playTicker();
        }

        let width = window.innerWidth; 
        let height = (1208 * window.innerWidth) / 1422;


        let geometry = new THREE.PlaneGeometry( width, height, 1, 1 );
        this.heightGeo = height;

        
        this.material = new THREE.MeshBasicMaterial({
            map:texture
        });

        this.plan = new THREE.Mesh( geometry, this.material );
        // this.plan.position.y = 781.5;
        
        this.scene = new THREE.Scene();
        this.scene.background = new THREE.Color( 0x141311 );
        this.scene.add(this.plan);

        this.renderer.render(this.scene, this.camera)
        

        //
        // partie post process
        //

        this.rendererP = this.renderer;
        this.sceneP = new THREE.Scene();
        
        this.dummyCameraP = new THREE.OrthographicCamera();
        const geometryP = new THREE.BufferGeometry();

        // Triangle expressed in clip space coordinates
        const vertices = new Float32Array([
          -1.0, -1.0,
          3.0, -1.0,
          -1.0, 3.0
        ]);

        geometryP.setAttribute('position', new THREE.BufferAttribute(vertices, 2));

        const resolutionP = new THREE.Vector2();
        this.rendererP.getDrawingBufferSize(resolutionP);

        this.targetP = new THREE.WebGLRenderTarget(resolutionP.x, resolutionP.y, {
            format: THREE.RGBFormat,
            stencilBuffer: false,
            depthBuffer: true,
        });

        
        this.materialP = new THREE.RawShaderMaterial({
            vertexShader: `
                precision highp float;
                attribute vec2 position;

                //varying vec2 vPosition;

                void main() {
                    gl_Position = vec4(position, 1.0, 1.0);
                }
            `,
            fragmentShader: `
                precision highp float;
                uniform sampler2D uScene;
                uniform vec2 uResolution;
                uniform float uIntensite;

                //varying vec2 vPosition;

                void main() {
                    vec2 uv = gl_FragCoord.xy / uResolution.xy;

                    // base
                    uv.x += sin((uv.y - 0.5) * 3. + 3.14/2. ) / 14. * ((uv.x - 0.5) * 4.) * uIntensite/3.;
                    // uv.x += sin((uv.y - 0.5) * 3. + 3.14/2. ) / 14. * ((uv.x * 3./2.) - 1.) * uIntensite;

                    gl_FragColor = texture2D(uScene, uv);
                }
            `,
            uniforms: {
                uScene: { value: this.targetP.texture },
                uResolution: { value: resolutionP },
                uIntensite: {value: 0}
            },
        });

        


        // TODO: handle the resize -> update uResolution uniform and this.target.setSize()

        const triangleP = new THREE.Mesh(geometryP, this.materialP);
        // Our triangle will be always on screen, so avoid frustum culling checking
        triangleP.frustumCulled = false;
        this.sceneP.add(triangleP);

        //
        // traitements
        //
        this.ySet = gsap.quickSetter(this.camera.position, "y");
        this.posY = 0;
        this.varia = 0;
        this.deltaY = 0;

        this.inten = gsap.quickSetter(this.materialP.uniforms.uIntensite, "value");

        //
        //
        // RESIZE()
        //
        //
        this.resize = this.eventResize.bind(this);
        window.addEventListener('resize', this.resize);
    }

    eventResize() {
        // console.log('ici')

        // basic
        // this.W = window.innerWidth
        // this.H = window.innerHeight
        // this.renderer.setSize( this.W, this.H )

        // this.frustumSize = this.H
        // this.aspect = this.W/this.H

        // this.camera.position.y = ((1208 * window.innerWidth) / 1422) / 2 + 0.5 * window.innerHeight;

        // this.camera.aspect = this.aspect
        // this.camera.updateProjectionMatrix()

        // this.scene.remove(this.plan)

        // let width = window.innerWidth; 
        // let height = (1208 * window.innerWidth) / 1422;


        // let geometry = new THREE.PlaneGeometry( width, height, 1, 1 );
        // this.heightGeo = height;
        // this.plan = new THREE.Mesh( geometry, this.material );
        // this.scene.add(this.plan)


        //
        //
        //
        //
        // _self.renderer.forceContextLoss();
        // _self.renderer.context = null;
        // _self.renderer.domElement = null;
        // _self.renderer = null;
    }

    lancerRaf() {
        gsap.ticker.add(this.addTicker);
    }

    killRaf() {
        gsap.ticker.remove(this.addTicker);
    }

    // wheel()
    // {
    //     window.addEventListener('wheel', this.ref = (e) => {
    //         this.deltaY = e.deltaY;
            
    //         window.clearTimeout( this.isWheeling );
    //         this.isWheeling = setTimeout( (e) => {
    //             this.deltaY = 0;
    //         }, 66); // IMPORTANT -> 66 normalement // 1000 si on mets le render normal quand deltaY = 0
    //     }, {passive: true});
    // }

    playTicker() {

        // console.log('ONNNN')

        const gDt = gsap.ticker.deltaRatio();
        const dt = 1.0 - Math.pow(1.0 - 0.1, gDt);//0.1 = speed

        //if(document.querySelector('.partieSticky').getBoundingClientRect().top < -window.innerHeight/2){
        //this.ySet(1.5 * window.innerHeight + document.querySelector('.partieSticky').getBoundingClientRect().top + window.innerHeight/2);
        //}

        this.ySet(document.querySelector('.partieSticky').getBoundingClientRect().top + ((1208 * window.innerWidth) / 1422) / 2 + 1 * window.innerHeight);


        
        if(Math.abs(this.varia/this.diviseur) > 1){ // /40
            this.inten(1)
        }else{ // /40
            this.inten(Math.abs(this.varia/this.diviseur)) // /40
        }


        // this.varia += (this.deltaY/2 - this.varia) * dt;
        this.varia += (this.diff/2 - this.varia) * dt;
        

        this.rendererP.setRenderTarget(this.targetP);
        this.rendererP.render(this.scene, this.camera);
        this.rendererP.setRenderTarget(null);
        this.rendererP.render(this.sceneP, this.dummyCameraP);



        // part delta au scroll
        this.distCur = window.pageYOffset;
        this.diff = this.distCur - this.distOld;
        this.distOld = this.distCur;

    }


    destroyWebgl() {
        window.removeEventListener('resize', this.resize)

        this.killRaf();
        this.renderer.clear()
        this.rendererP.clear()
    }
}