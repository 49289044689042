import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
// import {scroll} from "modules/constants"
import {lenis} from "./constants"
import {WebglProcess} from "WebglProcess"
import {isTouch, getTheOs} from "modules/utils.js"


export class Process{
    constructor(){

        //gsap.delayedCall(0.2, () => {
            //splitLines(document.querySelector('h1'), "<span>", "</span>")
        gsap.to('.fakeH1 span', {
            y:'0%',
            autoAlpha:1,
            ease:'power3.out',
            stagger:0.1,
            duration:1.5,
            delay:0.8
        })
        //});

        // if(window.innerWidth > 900){
        //     gsap.to('.innerRetail', {
        //         y:'-' + window.innerHeight/2 + 'px',
        //         ease:'none',
        //         scrollTrigger: {
        //             trigger:'body',
        //             start:'top top',
        //             end:'+=' + window.innerHeight/2 + 'px',
        //             scrub: true,
        //         }
        //     })
        // }

        // gsap.to('h1', {
        //     y:'10%',
        //     ease:'none',
        //     scrollTrigger: {
        //         trigger:'.p-hero .droite',
        //         start:'top top',
        //         endTrigger:'.endTrigger',
        //         end: 'bottom bottom-=130',
        //         scrub: true
        //     }
        // })

        if(window.innerWidth >= 768){

            let distHero = 0.257
            if(window.innerWidth <= 1280){
                distHero = 0.135
            }

            gsap.to('.masque', {
                x:-distHero * window.innerWidth + 'px',
                ease:'power2.inOut',
                scrollTrigger: {
                    trigger:'.p-hero .droite',
                    start:'top top',
                    end: '+=1350',
                    scrub: true
                }
            })

            gsap.to('.p-flex1 p', {
                x:distHero * window.innerWidth + 'px',
                ease:'power2.inOut',
                scrollTrigger: {
                    trigger:'.p-hero .droite',
                    start:'top top',
                    end: '+=1350',
                    scrub: true
                }
            })

            let distParts = 0.702
            if(window.innerWidth <= 1280){
                distParts = 0.756
            }

            let btn = document.querySelector('.p-parts button.actif')
            gsap.to('.p-parts', {
                width:distParts * window.innerWidth + 'px',
                ease:'power2.inOut',
                onUpdate:() => {

                    const dist2Bouton = document.querySelectorAll('.p-parts button')[1].getBoundingClientRect().left - (document.querySelectorAll('.p-parts button')[0].getBoundingClientRect().left + document.querySelectorAll('.p-parts button')[0].offsetWidth)
                    const largeurBouton = btn.offsetWidth;
                    const widthProgress = largeurBouton + dist2Bouton/2

                    btn.querySelector('.progress').style.width = widthProgress + 'px'
                    
                },
                scrollTrigger: {
                    trigger:'.p-hero .droite',
                    start:'top top',
                    end: '+=1350',
                    scrub: true
                }
            })

        }

        // gsap.to('.p-parts .progress div', {
        //     scaleX:1,
        //     ease:'none',
        //     scrollTrigger: {
        //         trigger:'.p-hero .droite',
        //         start:'top top',
        //         end:'bottom bottom',
        //         scrub:true
        //     }
        // })


        gsap.to('.p-hero .gauche img', {
            opacity:0.5,
            ease:'none',
            scrollTrigger: {
                trigger:'.p-hero .droite',
                start:'top top',
                endTrigger:'.endTrigger',
                end: 'bottom bottom-=130',
                scrub: true
            }
        })



        let delayPush = 0;
        document.querySelectorAll('.unPush').forEach(el => {
            gsap.to(el.querySelector('.trait'), {
                scaleX:1, 
                duration:1.4,
                delay: delayPush,
                ease:'power4.out',
                scrollTrigger: {
                    trigger:el,
                    start:'bottom bottom-=130px'
                }
            })

            delayPush += 0.2;
        })

        if(window.innerWidth >= 900){

            gsap.to('header .toHome', {
                y:'-100px',
                ease:'none',
                autoAlpha:0,
                scrollTrigger: {
                    trigger: '.p-hori',
                    start:'top top+=100px',
                    end:'+=100px',
                    scrub:true
                }
            })
        }

        // HORI
        let ajout = 0;
        if(window.innerWidth <= 900){
            ajout = 60
        }

        const cont = document.querySelector('.container')
        gsap.to('.container', {
            x:-1 * (cont.offsetWidth - window.innerWidth) + 'px',
            ease:'power1.inOut',
            scrollTrigger: {
                trigger:'.p-hori',
                start:'top top+=' + ajout,
                end: '+=' + (cont.offsetWidth - window.innerWidth) + 'px',
                scrub:true,
                pin:true
            }
        })

        document.querySelectorAll('.p-horiT').forEach(el => {
            gsap.to(el.querySelector('.traitHori'), {
                scaleY:1,
                opacity:0.2,
                duration:1.4,
                ease:'power4.out',
                scrollTrigger: {
                    trigger:'.p-innerHori',
                    start:'top top-=' + (el.getBoundingClientRect().left - 1*window.innerWidth + el.offsetWidth),
                }
            })
        })

        document.querySelectorAll('.p-horiSec').forEach(el => {
            gsap.to(el.querySelector('.pSpe'), {
                opacity:1,
                y:'0px',
                duration:1,
                ease:'power2.inout',
                scrollTrigger: {
                    trigger:'.p-innerHori',
                    start:'top top-=' + (el.getBoundingClientRect().left - window.innerWidth),
                }
            })
            
            gsap.to(el.querySelector('.preTHori'), {
                opacity:1,
                delay:0.4,
                duration:1,
                ease:'power2.inOut',
                scrollTrigger: {
                    trigger:'.p-innerHori',
                    start:'top top-=' + (el.getBoundingClientRect().left - window.innerWidth),
                }
            })
            
        })

        let multi = 1;
        if(window.innerWidth < 768){
            multi = 2
        }
        gsap.set('#shape', {
            scaleX: 0.22 * multi * window.innerWidth / 159,
            scaleY: 0.323 * multi * window.innerWidth / 234,
        })

        // const distFond = document.querySelector('.p-hori2').getBoundingClientRect().left + document.querySelector('.p-hori2').offsetWidth;
        // gsap.to('.p-hori2 .imgFond', {
        //     x:'0%',
        //     ease:'none',
        //     scrollTrigger: {
        //         trigger:'.p-hori',
        //         start:'top top',
        //         end: '+=' + distFond + 'px',
        //         scrub: true
        //     }
        // })


        const distMask = document.querySelector('.imgMasque').getBoundingClientRect().left + document.querySelector('.imgMasque').offsetWidth;
        gsap.to('.imgMasque img', {
            x:'0%',
            ease:'none',
            scrollTrigger: {
                trigger:'.p-hori',
                start:'top top+=' + ajout,
                end: '+=' + distMask + 'px',
                scrub: true
            }
        })

        gsap.to('header .toHome', {
            y:'0px',
            ease:'none',
            immediateRender:false,
            autoAlpha:1,
            scrollTrigger: {
                trigger: '.p-better',
                start:'top top',
                end:'+=100px',
                scrub:true
            }
        })


        // bordures
        // gsap.to('.p-better', {
        //     autoAlpha:0,
        //     ease:'none',
        //     scrollTrigger: {
        //         trigger:'.p-better',
        //         start:'bottom bottom+=' + window.innerHeight,
        //         end:'+=' + window.innerHeight,
        //         scrub: true
        //     }
        // })

        gsap.to('.bordG, .bordD, .triangle', {
            x:'0%',
            ease:'none',
            scrollTrigger: {
                trigger:'.p-steps',
                start:'top top',
                end:'+=' + window.innerHeight,
                scrub: true,
                onEnter: () => {
                    document.querySelector('.bordures').classList.add('on')
                    document.getElementById('webgl').classList.add('on')
                    console.log('RAF ON')
                    this.webglP.lancerRaf()
                },
                onLeaveBack: () => {
                    document.querySelector('.bordures').classList.remove('on')
                    document.getElementById('webgl').classList.remove('on')
                    console.log('RAF OFF')
                    this.webglP.killRaf()
                }
            }
        })
        gsap.to('.bordH, .bordB', {
            y:'0%',
            ease:'none',
            scrollTrigger: {
                trigger:'.p-steps',
                start:'top top',
                end:'+=' + window.innerHeight,
                scrub: true
            }
        })



        document.querySelectorAll('.uneStep').forEach(el => {

            // const elApres = el.nextElementSibling;
            ScrollTrigger.create({
                trigger:el,
                start:'top top+=' + ajout,
                end:'+=' + el.offsetHeight,
                pin: true,
                pinSpacing:false
            })

            // gsap.to(el, {
            //     autoAlpha:0,
            //     ease:'none',
            //     scrollTrigger: {
            //         trigger:elApres,
            //         start:'top top+=' + 0.3*window.innerHeight,
            //         end:'+=' + 0.1*window.innerHeight,
            //         scrub:true
            //     }
            // })
        })

        ScrollTrigger.create({
            trigger:'footer',
            start:'top top',
            onEnter: () => {
                console.log('RAF OFF')
                this.webglP.killRaf()
            },
            onLeaveBack: () => {
                console.log('RAF ON')
                this.webglP.lancerRaf()
            }
        })

        gsap.delayedCall(1, () => {
        //     if(!isTouch() && getTheOs() != 'Windows'){
        //         scroll.resize()
        //         scroll.wheel()
        //     }
            if(!isTouch()){
                lenis.start();
            }
        })


        //
        //
        // webgl
        //
        //
        this.webglP = new WebglProcess();

    }

    clearWebgl(){
        this.webglP.destroyWebgl();
    }
}